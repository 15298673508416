.hero {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 4rem 2rem;
    background-color: #214F4B; /* Background color */
    color: #EFF1F3; /* Light text color */
}

.hero-content {
    max-width: 600px;
    margin: 1rem;
    text-align: left;
}

.hero-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #EFF1F3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.hero-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: #EFF1F3;
    font-family: 'Roboto';
}

.hero-content .btn-primary {
    background-color: #F96E46; /* Burnt Sienna */
    color: #EFF1F3;
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border-radius: 5px;
}

.hero-image {
    max-width: 400px;
    margin: 1rem;
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.hero-image img {
    width: 100%;
    display: block;
    border-radius: 15px;
}

.highlight a {
    text-decoration: none;
    color: #F96E46; /* Violet */
}

.highlight a:hover {
    text-decoration: underline;
    color: #f99346;
}

/* Media query for screens 500px or narrower */
@media (max-width: 500px) {
    .hero {
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center align content */
        text-align: center; /* Center align text */
    }

    .hero-content {
        max-width: 100%; /* Allow the text to take up full width */
    }

    .hero-image {
        width: 200px; /* Adjust the size for smaller screens */
        height: 200px;
        margin-top: 4rem; /* Add spacing above the image */
    }
}
