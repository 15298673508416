.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100%; /* Ensure the main container fills the viewport height */
}

.content {
    flex: 1; /* Pushes the footer down when content is short */
    display: flex;
    flex-direction: column;
}

.wave-divider {
    position: relative;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: -1; /* Ensure the wave stays behind the content */
    margin-top: -10rem; /* Pull the wave closer to the Hero section */
    margin-bottom: -10rem; /* Pull the wave closer to the Projects section */
}

.wave {
    background-color: transparent;
    display: block;
    width: 100%;
    height: 20rem; /* Adjust height for your design */
}

.wave-bottom {
    transform: rotate(180deg); /* Flip the bottom wave vertically */
}
