.tech-stack {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 12rem;
    padding: 2rem;
    background-color: #EFF1F3; /* Light background */
    border-radius: 12px;
}

.stack-item {
    text-align: center;
    flex: 1;
    padding: 1rem;
    border-radius: 8px;
    background-color: #FFFFFF; /* White for contrast */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stack-item h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #246A73; /* Caribbean Current */
    margin-bottom: 0.5rem;
}

.stack-item p {
    font-size: 1rem;
    color: #495057; /* Neutral text color */
}

.project-details {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #FFFFFF; /* White background */
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details-item {
    margin-bottom: 1rem;
}

.project-details h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #214F4B; /* Dark Slate Gray */
    margin-bottom: 1rem;
}

.details-item h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #246A73; /* Caribbean Current */
    margin-bottom: 0.5rem;
}

.details-item p {
    font-size: 1rem;
    color: #495057; /* Neutral text color */
    font-family: 'Roboto';
}


@media (max-width: 768px) {
    .tech-stack {
        flex-direction: column;
        align-items: center;
    }

    .stack-item {
        width: 100%; /* Full width for smaller screens */
        margin-bottom: 1rem; /* Space between items */
    }

    .project-details {
        flex-direction: column;
        align-items: center;
        margin: 1rem;
    }

    .project-details h2 {
        font-size: 1.5rem;
    }

    .details-item h3 {
        font-size: 1rem;
    }

    .details-item p {
        font-size: 0.9rem;
    }

    .details-item {
        width: 100%; /* Full width for smaller screens */
        margin-bottom: 1rem; /* Space between items */
    }
}
