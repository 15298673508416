.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #EFF1F3; /* Light background */
    color: #214F4B; /* Dark text */
}

.not-found h1 {
    font-size: 6rem;
    margin: 0;
    color: #F96E46; /* Burnt Sienna */
    font-family: 'Anton';
}

.not-found p {
    font-size: 1.5rem;
    margin: 0 0;
    font-family: 'Nunito';
}

.not-found .btn-primary {
    background-color: #214F4B;
    color: #EFF1F3;
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
}

.not-found .btn-primary:hover {
    background-color: #F96E46;
}
