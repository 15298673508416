/* Projects Section */
.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
    background-color: #EFF1F3; /* Light background color for contrast */
    color: #214F4B; /* Dark green for text */
  }
  
  /* Project Content */
  .project-content {
    max-width: 800px;
    text-align: left;
    margin: 2rem;
    background-color: transparent; /* Dark green background */
    color: #EFF1F3; /* Light text color for contrast */
    border-radius: 10px;
    padding: 2rem;
  }
  
  .project-content h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #214F4B; /* Highlight color */
  }
  
  .project-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: black;
  }
  
  .project-content .btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #F96E46; /* Violet */
    color: #EFF1F3;
    text-decoration: none;
    font-size: 1.1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .project-content .btn:hover {
    background-color: #214F4B; /* Dark green on hover */
  }
  
  /* Alignments */
  .project {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .project.align-left {
    flex-direction: row;
  }
  
  .project.align-right {
    flex-direction: row-reverse;
  }
  
  .project .project-content {
    width: 48%;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .projects {
      padding: 2rem 1rem;
    }
  
    .project {
      flex-direction: column;
    }
  
    .project .project-content {
      width: 100%;
      margin: 1rem 0;
    }
  
    .project-content h3 {
      font-size: 1.8rem;
    }
  
    .project-content p {
      font-size: 1rem;
    }
  
    .project-content .btn {
      font-size: 1rem;
    }
  }
  