/* Container */
.about-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 4rem 2rem;
    background-color: #214F4B; /* Background color */
    color: #EFF1F3; /* Light text color */
}

/* Text Section */
.about-content {
    max-width: 600px;
    margin: 1rem;
    text-align: left;
}

.about-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #EFF1F3;
}

.about-details p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: #EFF1F3;
    font-family: 'Roboto';
}

/* Highlighting Keywords */
.highlight {
    color: #F96E46; /* Violet */
    font-weight: bolder;
}

/* Image Section */
.about-image {
    max-width: 400px;
    margin: 1rem;
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.about-image img {
    width: 100%;
    display: block;
    border-radius: 15px;
}

.about-page .wave {
    position: absolute;
}

.contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.contact-section a {
    color: #EFF1F3;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #F96E46; /* Violet */
    transition: background-color 0.3s;
}

.contact-section a:hover {
    background-color: #214F4B; /* Dark green */
}

.extra-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding: 4rem 2rem;
    background-color: #EFF1F3; /* Background color */
    color: #214F4B; /* Light text color */
    margin-top: 10rem;
}

.extra-container p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: black;
    font-family: 'Roboto';
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .about-container {
        flex-direction: column-reverse;
        text-align: center;
        padding: 2rem 1rem;
    }

    .about-content {
        max-width: 100%;
        margin: 1rem 0;
    }

    .about-image {
        max-width: 100%;
    }

    .about-content h1 {
        font-size: 2.5rem;
    }

    .about-details p {
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .extra-container {
        padding: 2rem 1rem;
    }
}
