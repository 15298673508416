/* Footer Styles */
.footer {
    background-color: #214F4B; /* Dark Slate Gray */
    color: #EFF1F3; /* Light Text */
    padding: 2rem 1rem;
    text-align: center;
    margin-top: 5rem; /* Adds space above the footer */
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    gap: 3rem; /* Increased gap between sections */
}

.footer-section {
    flex: 1;
    min-width: 250px; /* Ensures proper spacing for smaller screens */
    text-align: left;
}

.footer-section h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #F96E46; /* Accent Color */
    font-weight: bold;
}

.footer-section p, .footer-section a {
    font-size: 1rem;
    line-height: 1.8;
    color: #EFF1F3;
    text-decoration: none;
}

.footer-section a:hover {
    color: #F96E46;
    text-decoration: underline;
}

.footer-information {
    flex: 1;
    display: flex;
    gap: 2rem;
    width: 75%;
}

.social-media {
    display: flex;
    gap: 1rem;
}

.copyright {
    text-align: left;
    width: 100%;
    margin-top: 2rem;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }

    .footer-information {
        flex: 1;
        display: flex;
        gap: 2rem;
        width: 75%;
    }

    .footer-section {
        text-align: center;
        min-width: auto; /* Let sections adjust to screen size */
        margin: unset; /* Remove margin for better alignment */
    }

    .social-media {
        justify-content: center;
        min-width: auto;
    }

    .footer-section h3 {
        font-size: 1.3rem; /* Adjust heading size for mobile */
    }

    .footer-section p, .footer-section a {
        font-size: 1.1rem; /* Slightly larger text for better readability on mobile */
    }

    .copyright {
        text-align: center; /* Center the text */
        width: 100%; /* Full width */
    }
}
