.navbar {
    background-color: transparent; /* Light background */
    position: sticky;
    top: 0;
    z-index: 1000;
    align-items: center;
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    margin: 0;
    padding: 1.25rem 0;
    justify-content: center;
    background: #214F4B;
  }
  
  .navbar ul li a {
    color: #EFF1F3; /* Default link color */
    position: relative;
    text-decoration: none;
    transition: color 0.3s ease;
    font-family: 'Poppins';
  }
  
  .navbar ul li a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #F96E46; /* Line color */
    bottom: -6px;
    left: 0;
    transform-origin: right;
    transform: scaleX(0); /* Initially hidden */
    transition: transform 0.3s ease-in-out;
  }
  
  .navbar ul li a:hover::before {
    transform-origin: left;
    transform: scaleX(1); /* Line becomes visible on hover */
  }
  
  .navbar ul li a.active::before {
    transform-origin: left;
    transform: scaleX(1); /* Line remains visible for active links */
  }
  
  .navbar ul li a.active {
    color: #EFF1F3; /* Optional: Change text color for active links */
  }

  .wave-container {
    position: absolute;
    width: 100%;
    height: 200px; /* Adjust height for wave */
    overflow: hidden;
    z-index: -1; /* Ensure the wave stays behind the navigation items */
    line-height: 0;
    background: transparent;
}

.wave-container .wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1; /* Ensure the wave stays behind the navigation items */
}
  